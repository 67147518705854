import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormikHelpers, FormikValues, Form, Formik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
// @ts-ignore
import Layout from "../layouts";
// @ts-ignore
import i1Img from "../assets/img/coaches/clarisse.png";
import { Link } from "gatsby";
// @ts-ignore
import microsoftLogo from "../assets/img/companies/microsoft.png";
// @ts-ignore
import boltLogo from "../assets/img/companies/boltLogo.png";
// @ts-ignore
import gorgiasLogo from "../assets/img/companies/gorgiasLogo.png";
// @ts-ignore
import hylandLogo from "../assets/img/companies/hylandLogo.png";
// @ts-ignore
import meilleursLogo from "../assets/img/companies/meilleursLogo.png";
// @ts-ignore
import morningLogo from "../assets/img/companies/morningLogo.png";
// @ts-ignore
import enedisLogo from "../assets/img/companies/enedis.png";
// @ts-ignore
import qontoLogo from "../assets/img/companies/qontoLogo.png";
// @ts-ignore
import hecLogo from "../assets/img/companies/HEC.png";
import { CompanySize } from "../constant/Common";
import InputFormik from "../components/formik/InputFormik";
import TextareaFormik from "../components/formik/TextareaFormik";
import SelectFormik from "../components/formik/SelectFormik";
import PhoneNumberInputFormik from "../components/formik/PhoneNumberInputFormik";
// @ts-ignore
import requestCallIcon from "../assets/img/icons/request-call.svg";
// @ts-ignore
import scheduleDemoIcon from "../assets/img/icons/schedule-demo.svg";

const TalkToExpert = () => {
  const [selectedTab, setSelectedTab] = useState("call");

  const init = {
    firstName: "",
    lastName: "",
    workEmail: "",
    phoneNumber: "",
    companyName: "",
    companySize: "",
    howCanWeHelp: "",
    howDidYouHear: "",
  };
  const privateDomains = ["gmail.com", "yahoo.com", "hotmail.com", "aol.com", "msn.com", "live.com", "outlook.com", "icloud.com", "zoho.com", "yandex.com"];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    companySize: Yup.object().required("Required"),
    companyName: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    workEmail: Yup.string()
      .email("Invalid email")
      .required("Work email is required")
      .test("is-private-email", "Private emails are not allowed", function (value) {
        if (value) {
          const emailDomain = value.split('@')[1];
          return !privateDomains.includes(emailDomain);
        }
        return false;
      }),
  });
  const showToast = () => {
    toast.success("Success!", {
      position: "top-center",
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSubmit = async (
    data: FormikValues,
    actions: FormikHelpers<any>
  ) => {
    const body = {
      topic: "Landing page: Talk-to-expert",
      data: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        workEmail: data?.workEmail,
        phoneNumber: data?.phoneNumber,
        companyName: data?.companyName,
        companySize: data?.companySize?.value,
        howCanWeHelp: data?.howCanWeHelp,
        howDidYouHear: data?.howDidYouHear,
      },
    };
    console.log(data, body);
    try {
      const response = await axios.post(
        "https://api.coachello.io/api/sns/pass-to-coachello",
        body
      );

      // Handle the response as needed (e.g., show a success message)
      console.log("API success:");
      showToast();
      // Clear the form after successful submission
      actions.resetForm();
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error submitting the form:", error);
    }

    // @ts-ignore
    window.dataLayer.push({
      event: "RequestCall",
      email: data?.workEmail,
      phone_number: data?.phoneNumber,
      first_name: data?.firstName,
      last_name: data?.lastName,
      company_name: data?.companyName,
      company_size: data?.companySize?.value,
      comments: data?.howCanWeHelp,
    });
  };

  const changeTab = (name: string) => {
    setSelectedTab(name);
  };
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";

    // Create a div element to hold the Meetings iframe
    const container = document.createElement("div");
    container.className = "meetings-iframe-container";
    container.setAttribute(
      "data-src",
      "https://meetings.hubspot.com/quentin-bouche/intro-coachello?embed=true"
    );

    // Append the script and div elements to the section you want to embed it in
    const targetSection = document.getElementById("your-section-id"); // Replace with the actual ID of your section
    if (targetSection) {
      targetSection.appendChild(script);
      targetSection.appendChild(container);
    }

    // Clean up when the component unmounts
    return () => {
      if (targetSection) {
        targetSection.removeChild(script);
        targetSection.removeChild(container);
      }
    };
  }, []);
  return (
    <Layout>
      {/*section*/}
      <div className="flex flex-col gap-5 section bg-warm lg:flex-row">
        <div className="lg:w-1/2">
          <h1 className="leading-[1.1] mb-8">Comment peut on vous aider?</h1>
          <p className="mb-2">Si vous êtes ici, plusieurs scénarios:</p>
          <ul className="flex flex-col gap-2 pl-4">
            <li className="list-item">
              Vous voulez en savoir plus sur Coachello
            </li>
            <li className="list-item">
              Vous souhaitez lancer des programmes de coaching
            </li>
            <li className="list-item">
            Vous avez un projet de formation et vous avez besoin de notre aide
            </li>
            <li className="list-item">
              Vous voulez voir une démo de l’application Coachello
            </li>
            <li className="list-item">Vous voulez devenir partenaire</li>
          </ul>

          <div className="p-5 mt-5 rounded-2xl bg-green/50">
            <b>
              "Coachello est exactement la solution que nous recherchions pour
              lancer des programmes de coaching pour nos managers. Nous
              apprécions la flexibilité et le modèle financier. L'équipe nous a
              aidé à créer un programme personnalisé qui correspond à la culture
              de notre entreprise. Mon coaching s'est très bien passé, les
              sessions ont été bénéfiques tant sur le plan personnel que
              professionnel"
            </b>
            <div className="flex items-center gap-5 mt-5">
              <img className="rounded-full w-9 h-9" src={i1Img} />
              <p>Clarisse Berriau - DRH Meilleurs Agents</p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <div className="px-5">
            <nav className="flex items-center justify-evenly" aria-label="Tabs">
              <button
                onClick={() => changeTab("call")}
                className={
                  "inline-flex items-center w-full pb-2 bg-transparent cursor-pointer " +
                  (selectedTab == "call"
                    ? "font-bold border-b-4"
                    : "font-medium border-b border-b-gray-500")
                }
              >
                <img src={requestCallIcon} alt="Request a call" />
                Demander un RDV
              </button>
              <button
                onClick={() => changeTab("demo")}
                className={
                  "inline-flex items-center w-full pb-2 bg-transparent cursor-pointer " +
                  (selectedTab == "demo"
                    ? "font-bold border-b-4"
                    : "font-medium border-b border-b-gray-500")
                }
              >
                <img src={scheduleDemoIcon} alt="Schedule a Demo" />
                Planifier une Demo
              </button>
            </nav>

            <div className="py-5">
              <div className={selectedTab !== "call" ? "hidden" : ""}>
                <Formik
                  id="talk-to-expert"
                  initialValues={init}
                  validationSchema={validationSchema} // Use the Yup schema
                  onSubmit={handleSubmit}
                >
                  <Form className="flex flex-col w-full gap-5">
                    <div className="flex flex-col gap-5 md:flex-row">
                      <InputFormik name="firstName" required label="Prénom" />
                      <InputFormik name="lastName" label="Nom" />
                    </div>
                    <div className="grid gap-5 lg:grid-cols-2">
                      <div className="flex-1 ">
                        <InputFormik
                          name="workEmail"
                          required
                          label="Email professionnel"
                          type="email"
                        />
                      </div>
                      <div className="flex-1 ">
                        <PhoneNumberInputFormik
                          name="phoneNumber"
                          label="Téléphone"
                        />
                      </div>
                    </div>
                    <div className="grid gap-5 md:grid-cols-2">
                      <InputFormik
                        name="companyName"
                        required
                        label="Nom de l’entreprise"
                      />

                      <SelectFormik
                        required
                        name="companySize"
                        className=""
                        options={CompanySize}
                        label="Taille de l’entreprise"
                      />
                    </div>
                    <TextareaFormik
                      name="howCanWeHelp"
                      label="Dites nous en plus !"
                    />
                    <TextareaFormik
                      name="howDidYouHear"
                      label="Comment avez vous connu Coachello?"
                    />
                    <p>
                      En prenant RDV avec nous, vous acceptez nos
                      <Link className="underline" to="/privacy-policy">
                        conditions générales
                      </Link>
                      .
                    </p>

                    <button
                      type="submit"
                      className="mr-auto bg-black rounded-full dashboard-button"
                    >
                      Valider
                    </button>
                    {/*<p>*/}
                    {/*  If you prefer to schedule a call, please click on this{" "}*/}
                    {/*  <Link className="underline" to="/privacy-policy">*/}
                    {/*    agenda*/}
                    {/*  </Link>*/}
                    {/*  and select the best time for you*/}
                    {/*</p>*/}
                  </Form>
                </Formik>
              </div>
              <div className={selectedTab !== "demo" ? "hidden" : ""}>
                <div id="your-section-id">
                  {/* Content of the section where you want to embed the script */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Section*/}
      <div className="section bg-warm-dark">
        <h2 className="text-center md:text-left">
          Rejoigner des entreprises incroyables comme:
        </h2>
        <div className="py-5 flex flex-wrap justify-center md:justify-start [&>div>img]:h-12">
          <div>
            <img src={microsoftLogo} />
          </div>
          <div>
            <img src={boltLogo} />
          </div>
          <div>
            <img src={gorgiasLogo} />
          </div>
          <div>
            <img src={hylandLogo} />
          </div>
          <div>
            <img src={meilleursLogo} />
          </div>
          <div>
            <img src={morningLogo} />
          </div>
          <div>
            <img src={enedisLogo} />
          </div>
          <div>
            <img src={qontoLogo} />
          </div>
          <div>
            <img src={hecLogo} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TalkToExpert;
